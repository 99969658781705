// @import "../../node_modules/bootstrap/scss/bootstrap"

@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";
// // @import "../../node_modules/bootstrap/scss/grid"
@import "../../node_modules/bootstrap/scss/buttons";
@import "../../node_modules/bootstrap/scss/carousel";
// @import "../../node_modules/bootstrap/scss/pagination"

$f-bg: #000000; //#f5f5f5

@mixin respond($breakpoint) {
    @if $breakpoint == mini {
        @media screen and (max-width: 670px) {
            @content; } }
    @if $breakpoint == small {
        @media screen and (min-width: 768px) {
            @content; } }
    @if $breakpoint == medium {
        @media screen and (min-width: 992px) {
            @content; } }
    @if $breakpoint == large {
        @media screen and (min-width: 1200px) {
            @content; } }
    @if $breakpoint == exlarge {
        @media screen and (min-width: 1440px) {
            @content; } }
    @if $breakpoint == wide {
        @media screen and (min-width: 2440px) {
            @content; } } }


a {
    // color: #337ab7
    text-decoration: none; }
// main
//     background-color: #EFEFEF

body {
    --choice-bg: #f5f5f5; // $f-bg
    // --choice-bg-light: rgba(11,11,11,0.5)
    // --light: #fafafa
    background-color: var(--choice-bg);
    @mixin darkmode {
        --choice-bg: #f50000; }
    @mixin darkmode {
        background-color: #ff0000; } }

html {
    --font: "Source Sans Pro", sans-serif;
    // --light: rgba(119,119,199,0)
    // --dark: #000
    // --header-text: var(--dark)

    // Main page
    @mixin darkmode {
        background-color: #ff0000; } }

section {
    text-align: center;
    background-size: cover;
    background-position: center;
    display: inline-block;

    h1 {
        text-align: center; }

    .full-width {
        .box {
            border-radius: 0; } }

    .box {
        background-color: var(--post-bg);
        border-radius: 4px;
        border-style: solid;
        border-width: 1px;
        border-color: var(--table-border);
        box-shadow: var(--table-border) 0px 2px 0px 0px;
        position: relative; } }

section, article, .excerpt {
    h1, h2, h3, h4, h5, h6 {
        color: #777777;
        font-weight: 200;
        line-height: 1; }
    p {
        color: #777777;
        padding: 0;
        margin-bottom: 10px; }
    h1 {

        @include respond(small) {
            font-size: 2em;
            letter-spacing: -0.02em; }
        @include respond(medium) {
            font-size: 3em;
            letter-spacing: -0.02em; }
        @include respond(large) {
            font-size: 3.5em;
            letter-spacing: -0.04em; }
        @include respond(exlarge) {
            font-size: 4em;
            letter-spacing: -0.05em; } }

    h2 {
        @include respond(small) {
            font-size: 1.75em; }
        @include respond(medium) {
            font-size: 2em; }
        @include respond(large) {
            font-size: 2.5em; }
        @include respond(exlarge) {
            font-size: 3em; } }
    h3, h4 {
        margin: 20px auto 10px auto;
        padding: 0; }
    a {
        color: #337ab7;
        text-decoration: none; }
    a:hover, a:focus {
        color: #23527c;
 } }        // text-decoration: underline


.remove-margin-top {
    margin-top: 0px;
    padding-top: 0px; }


.page {
    &-item {
        padding: 0rem;
        a {
            border: 1px solid rgb(221, 221, 221);
            color: #337ab7; }
        &.disabled {
            opacity: 0.7; }
        &:first-child, &:last-child {
            display: block; }
        &.active a {
            // background-color: darken($theme, 20%)
            z-index: 2;
            color: #fff;
            background-color: #337ab7;
            border-color: #337ab7;
            cursor: default; } }
    &-link {
        // padding: 0.25rem 0.75rem
        // background-color: $theme
        // color: $light
        border-radius: 0;
        color: #777777;
        background-color: #fff;
        border-color: #ddd;
 } }        // cursor: not-allowed
.upper-paginator {
    margin-bottom: 20px; }
.title-on-image {
    margin-top: 20px;
    margin-bottom: 10px;
    font-family: "Source Sans Pro", sans-serif;
    @include respond(mini) {
        margin-top: 35px; }
    @include respond(small) {
        font-size: 2em; }
    @include respond(medium) {
        font-size: 3em; }
    @include respond(large) {
        font-size: 3.5em; }
    @include respond(exlarge) {
        font-size: 4em; } }



section {
    &.title {
        text-align: left;
        margin-top: 50px;
        font-size: 3em;
        margin-top: 5px;
        color: #777; }
    .text-container {
        margin-left: auto;
        margin-right: auto;
        width: 100%; } }

ol {
    padding-inline-start: 40px;
    p {
        padding-left: 10px; } }

article {
    header {
        &.header-title {
            text-align: left;
            margin-top: 75px;
            // align-content: center
            .container {
                // padding: 15px 10% 0px 10%
                // background-color: var(--post-bg)
                background-color: var($f-bg); }

            .header-title-content {
                h1, p, h5 {
                    text-align: left;
                    text-weight: 200; } }
            .header-title-content-parent {
                @include respond(small) {
                    margin-left: 2%;
                    margin-right: 2%; }
                @include respond(medium) {
                    margin-left: 4%;
                    margin-right: 4%; }
                @include respond(large) {
                    margin-left: 6%;
                    margin-right: 6%; }
                @include respond(exlarge) {
                    margin-left: 10%;
                    margin-right: 10%; } } } }

    .post {
        margin-top: -15px;
        .container {
            background-color: var($f-bg); } //var(--post-bg)
        .post-content {
            padding: 15px 10% 0px 10%;
            @include respond(small) {
                margin-left: 2%;
                margin-right: 2%; }
            @include respond(medium) {
                margin-left: 4%;
                margin-right: 4%; }
            @include respond(large) {
                margin-left: 6%;
                margin-right: 6%; }
            @include respond(exlarge) {
                margin-left: 8%;
                margin-right: 8%; } }
        ul {
            margin-top: 0px;
            list-style-type: circle;
            list-style-position: outside;
            padding-left: 30px;
            margin-bottom: 15px;
            p {
                margin-bottom: 1px; }
            ul {
                list-style-type: square; } }
        ol {
            display: block;
            list-style-type: decimal;
            margin-block-start: 1em;
            margin-block-end: 1em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
            padding-inline-start: 40px;
            p {
                padding: 0px 0px 0px 10px; }


 } } }            // margin-bottom: 10px

section {
    &.snippet-page {
        text-align: left;
        margin: 55px auto;
        width: 100%;

        .snippet-content {
            @include respond(small) {
                margin-left: 1%;
                margin-right: 1%; }
            @include respond(medium) {
                margin-left: 1%;
                margin-right: 2%; }
            @include respond(large) {
                margin-left: 4%;
                margin-right: 4%; }
            @include respond(exlarge) {
                margin-left: 6%;
                margin-right: 6%; } } }

    /* BASIC */ }
section {
    &.basic {
        text-align: left;
        margin: 55px auto;
        width: 100%;

        .basic-content {
            // padding: 30px 0
            padding: 15px 10% 0px 10%;
            p {
                text-align: left;
                background-color: var(--post-bg);
 }                // padding: 15px 10px 50px
            img {
                max-width: 95%; } }

        li {
            display: list-item; }
        ul {
            list-style-type: circle;
            padding-left: 30px;
            margin-bottom: 15px;
            p {
                margin-bottom: 4px; }
            ul {
                list-style-type: square;
                ul {
                    list-style-type: disc; } } } } }

section {
    /*CALLOUT*/
    &.callout {
        margin: 55px auto;
        width: 100%;

        &:first-of-type {
            margin-top: -75px; }

        // margin-bottom: 55px
        // h2
        //     font-size: 2em
        .container {
            margin-left: auto;
            margin-right: auto; }
        .callout-inner {
            padding: 25px 15% 50px; } } }

article {
    &.content-page {
        background-color: var($f-bg);
        .basic {
            &:first-of-type {
                margin-top: -45px; }
            .basic-content {
                padding-left: 30px;
                color: #777777;
                // border-color: #777777
                background-color: #FFFFFF;
                h1, h2 {
                    text-align: center;

                    font-weight: 300;
                    @include respond(small) {
                        font-size: 1.5em; }
                    @include respond(medium) {
                        font-size: 1.75em; }
                    @include respond(large) {
                        font-size: 1.75em; }
                    @include respond(exlarge) {
                        font-size: 48px; } } } } }
    /* BANNER - Main image*/ }
section {
    &.banner {
        min-height: 250px;
        line-height: 250px;
        padding: 0 0 50px;
        position: relative;
        font-size: 16px;
        width: 100%;
        color: #FFFFFF;
        border-color: #FFFFFF;
        height: 100%;
        background-color: #000000;

        h1 {
            color: #ffffff; }

        .container {
            width: 100%;
            display: inline-block;
            vertical-align: middle;
            line-height: 0;
            position: relative;
            z-index: 1; }

        .bg-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0.5;
            z-index: 0; }

        .btn, a {
            display: inline-block;
            margin: 9px;
            max-width: none;
            font-size: 16px;
            padding: 12px;
            border-color: inherit;
            color: inherit;
            background: transparent;
            min-width: 200px;
            max-width: 80%;

            &:focus, &:hover {
                background-color: rgba(255, 255, 255, 0.2); } }

        .downloads {
            .focused {
                margin: 10px 0;
                &-link {
                    margin: 30px 0; } }
            .web-only {
                display: none; }

            .download-list {
                display: none; } }
        @include respond(mini) {
            min-height: 300px;
            line-height: 300px;
            padding: 0 0 75px; }
        @include respond(small) {
            min-height: 250px;
            line-height: 250px;
            padding: 0 0 75px; }
        @include respond(medium) {
            min-height: 500px;
            line-height: 500px;
            padding: 0 0 75px; }
        @include respond(large) {
            min-height: 600px;
            line-height: 600px;
            padding: 0 0 75px; }
        @include respond(exlarge) {
            min-height: 600px;
            line-height: 600px;
            padding: 0 0 75px; } } }




.feature-text {

    .heading {
        min-height: 143px;
        letter-spacing: -0.04em; } }
// section
//     h1
//         color: #fff

.nav_brand {
    &.nav_item {
        img {
            height: 40px; } } }
.nav {
    .nav_sub {
        .nav_child {
            &.nav_item {
                color: rgb(51,51,51);
                font-weight: 350;
                text-decoration-color: rgb(51,51,51); } }
        @include respond(mini) {
            background: #f5f5f5; } } }

.nav {
    .nav_parent {
        margin: 0; }
    .follow {
        vertical-align: center;
        a {
            &:hover {
                height: 100%;
                padding-top: 12px; } } }
    a {
        font-weight: 200;
        &:hover {
            background-color: rgba(68, 68, 68, 0.85);
            color: #eeeeee; } }
    &_open + &_sub {
        border-radius: 0 0 4px 4px;
        font-size: 16px;
        font-weight: 300; }
    &_item {
        padding: 0.4rem 1rem; } }



section {
    &.call-out-pro {
        margin-top: 75px;
        width: 100%; }

    &.callout-pro, .callout-inner-pro {
        padding: 25px 15% 50px; } }

section {
    &.features {
        margin-top: 75px;
        padding: 0px 0px 0px 0px;
        width: 100%;

        .box {
            padding: 15px 20px 65px;
            margin: 5px;
            @include respond(small) {
                padding: 15px 20px 65px;

                min-height: 210px; }
            @include respond(medium) {
                padding: 25px 30px 65px;
                height: 425px; }
            @include respond(large) {
                padding: 25px 30px 65px;
                height: 425px; }
            @include respond(exlarge) {
                padding: 25px 30px 65px;
                height: 425px; } }
        img {
            margin: 5px 0;
            max-width: 60px;
            @include respond(small) {
                max-width: 60px; }
            @include respond(medium) {
                max-width: 70px; }
            @include respond(large) {
                max-width: 100px; }
            @include respond(exlarge) {
                max-width: 100px; } }
        h2 {
            margin-bottom: 10px;
            margin-top: 20px;
            padding: 0 0 0 0;
            @include respond(small) {
                margin-bottom: 0px;
                margin-top: 0px; }
            @include respond(medium) {
                max-width: 100%; }
            @include respond(large) {
                max-width: 100%; }
            @include respond(exlarge) {
                max-width: 100%; } }

        .btn-wrap {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            text-align: center;
            padding-bottom: 20px; } } }
section {
    &.feed {
        margin: 5px auto;
        width: 100%;
        margin-top: 75px;
        .content {
            margin-right: auto;
            margin-left: auto; }
        .box {
            .btn-wrap {
                bottom: 0;
                left: 0;
                width: 100%;
                text-align: center;
                padding-bottom: 20px; }
            @include respond(small) {
                padding: 10px 10px 35px;
                min-height: 400px;
 }                // height: 450px
            @include respond(medium) {
                padding: 15px 20px 35px;
 }                // height: 450px
            @include respond(large) {
                padding: 25px 30px 35px;
                min-height: 425px;
 }                // height: 450px
            @include respond(exlarge) {
                padding: 25px 30px 35px;
                min-height: 425px;
 } }                // height: 450px
        p {
            padding: 0; } } }

section {
    &.featured_posts {
        margin-top: -75px;
        .carousel-control-prev, .carousel-control-next {
            background-color: rgba(255,255,255,0.7); }
        .carousel-indicators {
            background-color: rgba(255,255,255,0.7); } }
    .col-xs-12 {
        position: relative;
        min-height: 1px;
        padding-left: 15px;
        padding-right: 15px; }
    .carousel-inner {
        width: 75%;
        margin-left: auto;
        margin-right: auto;
        .carousel-item {
            @include respond(mini) {
                min-height: 400px; } } }
    .box {
        height: auto; } }


.excerpt {
    margin-left: 4%;
    margin-right: 4%;
    p, .pale {
        padding: 0px 0px; }
    .post_link {

        @include respond(small) {
            font-size: 1.75em; }
        @include respond(medium) {
            font-size: 2em; }
        @include respond(large) {
            font-size: 3em; }
        @include respond(exlarge) {
            font-size: 3em; } }

    .post_link, .post_cat {}

    .lead {
        p {
            font-text: 24px; } }
    .text-muted {
        color: #777777; } }

.sidebar {
    .category {
        font-size: 3em;
        margin-bottom: 50px; }
    .tags_list {
        margin-bottom: 20px;
        padding-left: 0; }
    .tags_nav, .sidebar_inner {
        width: 100%;
        text-align: left; }

    .post_tag {
        color: #555;
        position: relative;
        display: block;
        padding: 10px 15px;
        margin-bottom: -1px;
        background-color: #fff;
        border: 1px solid #ddd;
        width: 100%;
        margin: 0 0 0;
        &:focus {
            text-decoration: none;
            color: #555;
            background-color: var($f-bg); } }
    .count {
        float: right;
        font-size: 0.75em;
        line-height: 1.75em;
        vertical-align: middle;
        opacity: 0.75; } }

.container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
    @include respond(small) {
        width: 750px; }
    @include respond(medium) {
        width: 970px; }
    @include respond(large) {
        width: 1170px; }
    @include respond(exlarge) {
        width: 1430px; } }

.waside {
    .container {
        @include respond(small) {
            width: 550px; }
        @include respond(medium) {
            width: 770px; }
        @include respond(large) {
            width: 970px; }
        @include respond(exlarge) {
            width: 1130px; } } }

.col-third {
    float: left;
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    @include respond(small) {
        width: 100%; }
    @include respond(medium) {
        width: 33.3%; }
    @include respond(large) {
        width: 33.3%; }
    @include respond(exlarge) {
        width: 33.3%; } }

.col-third-half {
    float: left;
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    @include respond(small) {
        width: 100%; }
    @include respond(medium) {
        width: 50%; }
    @include respond(large) {
        width: 33.3%; }
    @include respond(exlarge) {
        width: 33.3%; } }

.btn {
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 6px 12px;
    font-size: 16px;
    line-height: 1.428571429;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }

.btn-default {
    border-color: #4677AA;
    color: #4677AA;
    background: #ffffff;
    &:active, &:hover, {
        color: #333;
        background-color: #d4d4d4;
        border-color: #8c8c8c; }
    &:hover {
        border-color: #4677AA;
        background-color: #4677AA;
        color: #ffffff; } }

.btn-primary {
    color: #fff;
    background-color: #337ab7;
    border-color: #2e6da4;
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 6px 12px;
    font-size: 16px;
    line-height: 1.428571429;
    border-radius: 4px;

    &:hover {
        color: #fff;
        background-color: #286090;
        border-color: #204d74;
        text-decoration-line: none; } }

.upper-space {
    height: 15px;
    width: 100%; }

.nav_open+.nav_sub::before {
    border-bottom: 0 solid var(--choice-bg); }


.carousel-control-prev-icon, .carousel-control-next-icon {
    cursor: pointer; }

.logo-text {
    font-weight: bold;
    padding-left: 10px;
    color: #eeeeee;
    font-size: 18px; }

.to_top {
    bottom: 10px;
    right: 10px; }

.buttons-in-row {
    p {
        width: 10px;
        display: inline;
        padding: 10px; } }

.release-notes {
    &.content-page {
        .basic {
            .basic-content {
                h1, h2 {
                    text-align: left; } } } } }

.pale {
    color: #777777; }
.lead {
    font-size: 24px;
    font-weight: 200; }
.posts, .upper-paginator {
    .page-title, h2, h1 {
        color: #777777;
        font-weight: 200; }
    .page-title, h1 {
        @include respond(small) {
            font-size: 1.75em; }
        @include respond(medium) {
            font-size: 2em; }
        @include respond(large) {
            font-size: 3em; }
        @include respond(exlarge) {
            font-size: 4em; } }
    h2 {
        font-size: 18px; }
    .post-title {
        font-weight: 200; } }


.nav_parent {
    border-radius: 0;
    &:hover {
        background-color: rgba(68,68,68,0.85);
        color: #eeeeee; } }

body, .nav {
    max-width: 100%; }


.nav_open+.nav_sub .nav_child+a {
    padding-top: 0.5rem;
    &:hover {
        background-color: #6ac0e7;
        color: #eeeeee; }
    @include respond(small) {
        padding-top: 0.5rem; }
    @include respond(medium) {
        padding-top: 0.5rem; }
    @include respond(large) {
        padding-top: 0.5rem; }
    @include respond(exlarge) {
        padding-top: 0.5rem; } }

.feature-page {
    section {
        &.basic {
            .basic-content {
                p {
                    background-color: rgba(255, 0, 0, 0); } } } } }

.blog-snippet, .blog-feature {
    h1 {
        text-align: left; }
    p {
        line-height: 1.5;
        font-weight: 300; } }

#categories-section {
    margin-top: 75px; }

.formit-video {
    // margin: 3rem
    margin-bottom: 3rem;
    iframe {
        // padding: 3rem
        // height: 350px
        // width: 800px
        border: 0px;
        @include respond(small) {
            height: 120px;
            width: 300px; }
        @include respond(medium) {
            height: 150px;
            width: 400px; }
        @include respond(large) {
            height: 400px;
            width: 800px; }
        @include respond(exlarge) {
            height: 450px;
            width: 800px; } }

    @include respond(small) {
        margin-left: 0;
        margin-right: 0; }
    @include respond(medium) {
        margin-left: 0;
        margin-right: 0; }
    @include respond(large) {
        margin-left: 3rem;
        margin-right: 3rem; }
    @include respond(exlarge) {
        margin-left: 3rem;
        margin-right: 3rem; } }

.bottom-searator {
    height: 5px; }
.simple-font-weight {
    font-weight: 300; }

.post-content, .content-page {
    strong {
        font-weight: bold;
        color: #5c5c5c; }
    h1, h2 {
        color: #5c5c5c; }
    h2 {
        font-weight: 300; } }

.side-toc {
    float: left;
    z-index: 1;
    height: auto;

    @include respond(mini) {
        width: 100%;
        margin-top: 25px;
        margin-bottom: 100px; }

    @include respond(small) {
        // width: 550px
        padding: 15px 0px 15px calc(50vw - 140px - 16vw - 4vw - 90px - 20px); }
    @include respond(medium) {
        // width: 770px
        padding: 15px 0px 15px calc(50vw - 110px - 16vw - 9vw - 90px - 20px); }
    @include respond(large) {
        // width: 970px  /160
        padding: 15px 0px 15px calc(50vw - 170px - 16vw - 10vw - 90px - 20px); }
    @include respond(exlarge) {
        // width: 1130px
        padding: 15px 0px 15px calc(50vw - 375px - 16vw - 90px - 20px); }
    @include respond(wide) {
        // width: 1130px
        padding: 15px 0px 15px calc(50vw - 565px - 16vw - 150px - 20px); }

    .side-toc-inner {
        background-color: var(--post-bg);
        min-width: 180px;
        position: -webkit-sticky;
        position: sticky;
        top: 65px;
        @include respond(mini) {
            min-width: 300px;
            left: calc(50vw - 150px); }
        @include respond(small) {
            min-width: 180px; }
        @include respond(wide) {
            min-width: 300px; } }

    h3 {
        color: #fff;
        background-color: #000;
        padding: 15px 0px;
        font-weight: 300;
        font-size: .9em; }

    ul {
        font-size: .6em;
        margin-top: 20px;
        margin-bottom: 30px;
        padding-left: 0px;
        list-style-type: none;
        list-style-position: outside;
        @include respond(mini) {
            font-size: 1em; }
        @include respond(small) {
            font-size: .6em; }
        @include respond(wide) {
            font-size: 1em; }

        p {
            margin-bottom: 1px; }
        ul {
            list-style-type: none; }
        li {
            margin: 7px; } } }

.page-formit-content {
    .grid-2 {
        gap: 0em;
        @include respond(small) {
            grid-template-columns: 8% 1fr; }
        @include respond(medium) {
            grid-template-columns: 18% 1fr; }
        @include respond(large) {
            grid-template-columns: 10% 1fr; }
        @include respond(exlarge) {
            grid-template-columns: 5% 1fr; } } }
